export default{
	// //usdt代币合约
	usdtCoin:require("../abi/usdt.json"),
	usdtCoinAddress:"0xA579fda72b2F56BE94805b27dE316acf5CF3Fb8d",  //主链

	//LR 代币
	mainCoin:require("../abi/LR.json"),
	mainCoinAddress:"0x95D77166AB9f5918375c61137F7d0ae07Fc1C6CE",


	//入金
	runCoin:require("../abi/run.json"),
	runCoinAddress:"0x46A9142118e92839e9c9B8cC8E2eE415be82ad6C"

}