<template>
  <div class="main-page">
    <Header msg="LR"  :leftArrow = 'false'></Header>

    <BScroll  class="content">


        <!-- banner -->
        <div class="banner">

            <van-swipe  :autoplay="5000" indicator-color="white" style="height:3rem">

                <!-- <van-swipe-item >
                        <div class="banner"  >
                            <img src="../assets/imger/banner.png" alt="" />
                        </div> 
                </van-swipe-item> -->

                <van-swipe-item v-for="item in bannerls"  :key="item" >
                    <div class="banner"  >
                        <img :src="item.url" alt="" />
                    </div> 
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- 黑洞地址 -->
        <div class="d-flex align-center justify-between hdong">

            <div class="lr">
                <img src="../assets/imger/lr.png" alt=""  class="lrlogo">
            </div>

            <div class="a0x00 d-flex align-center justify-center">
                <span>{{UU.adrTostr2(file.mainCoinAddress)}}</span>
                <div style="width:.22rem;margin-left:.1rem "  @click="copy(file.mainCoinAddress)">
                    <img src="../assets/imger/cc.png" alt="">
                </div>
            </div>


            <div class="mmove" >

                <a :href="LRcodeUrl" target="_blank">
                    <img src="../assets/imger/mmove.png" alt="">

                </a>
                
            </div>
        </div>

        <!-- 资产 -->
        <div class="accets">

            <div class="d-flex align-center justify-between inout">
                <div class="initem">

                    <div class="title">
                        <span>入金总额</span> <span class="coin"> USDT</span>
                    </div>

                    <div class="balance">
                        <span>{{ info.in_amount }}</span> 
                        <!-- <span class="coin"> USDT</span> -->
                    </div>
                </div>

                <div class="initem out">
                    <div class="title">
                        <span>出局总额</span> <span class="coin"> USDT</span>
                    </div>

                    <div class="balance">
                        <span>{{getBit(info.out_amount)}}</span>
                          <!-- <span class="coin"> USDT</span> -->
                    </div>
                </div>

            </div>

            <div class="cucc">
                
                <div class="ls">
                    <span 
                    @click="()=>{
                            $router.push('./lrls')
                        }"
                    >记录</span>
                </div>

                <div class="cuccInfo d-flex align-center justify-between">

                    <div class="title">
                        <span>产出总额</span>
                    </div>

                    <div class="balance">

                        <div>
                             <span>{{getBit(info.produce)}}</span>
                              <span class="coin"> LR</span>
                        </div>


                        <div class="usdtre">
                             <span>≈{{ getBit(info.produce_usdt)}}</span>
                             <span class="coin"> USDT</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>


        <!-- 注入USDT -->

        <div class="inusdt">

            <div class="down">
                <img src="../assets/imger/down.png" alt="">
            </div>

            <div class="title">
                <span>注入USDT</span>
            </div>

            <div class="tts">
                <span>注入的USDT数量，将添加到您的入金总额内</span>
            </div>
            <div class="tt">
                <img src="../assets/imger/tt.png" alt="">
            </div>

            <div class="btn d-flex align-center justify-center">

                <van-button 
                    round
                    color="#006CFF"
                    style="width:80%"

                    @click="()=>{
                        if($store.state.walletType){
                            Toast('观察钱包不能交易');
                            return
                        }
                        inShow=true;

                    } "
                >注入USDT</van-button>
            </div>
        </div>

        <!-- 代币信息 -->

        <div class="inusdt coinxx" style="height:7.2rem">

            <div class="down">
                <img src="../assets/imger/down.png" alt="">
            </div>

            <div class="title">
                <span>LR代币信息</span>
            </div>

            <div class="coinInfo">

                <div class="coinItem">
                    <span class="color">币名</span>
                    <span>{{coinxx.name}}</span>
                </div>

                <div class="coinItem">
                    <span class="color">发行总量</span>
                    <span>{{coinxx.totalNum /10000}} w</span>
                </div>

                <div class="coinItem">
                    <span class="color">总销毁数量</span>
                    <span>{{Number(coinxx.burnMount).toFixed(6)}}</span>
                </div>

                <div class="coinItem">
                    <span class="color">每日产出</span>
                    <span>1 w</span>
                </div>

                <div class="coinItem">
                    <span class="color">买入手续费</span>
                    <span>0%</span>
                </div>

                <div class="coinItem">
                    <span class="color">卖出手续费</span>
                    <span>{{ coinxx.fee }}% </span>
                </div>

                <div class="coinItem">
                    <span class="color">币价</span>
                    <span>1LR = {{ getBit(info.price) }} USDT</span>
                </div>
            </div>

           
        </div>

        <!-- 邀请码 -->
        <div class="yycode">

            <div class="down">
                <img src="../assets/imger/down.png" alt="">
            </div>

            <div class="title">
                <span>个人邀请码</span>
            </div>

            <div class="codenum d-flex align-center justify-between">

                <div class="code">
                    <span>{{info.code}}</span>
                </div>

                <div class="copy"   @click="copy(info.code)">
                    <img src="../assets/imger/copy.png" alt="">
                </div>
            </div>
        </div>


        <!-- 个人信息 -->

        <div class="inusdt coinxx" style="height:6rem">

            <div class="down">
                <img src="../assets/imger/down.png" alt="">
            </div>

            <div class="title">
                <span>个人信息</span>
            </div>

            <div class="coinInfo">

                <div class="coinItem">
                    <span class="color">VIP等级</span>
                    <span>VIP{{ info.level }}</span>
                </div>

                <div class="coinItem">
                    <span class="color">上级地址</span>

                    <template v-if="info.f_address">
                        <div class="d-flex align-center"  @click="copy(info.f_address)"> 
                            <span>{{UU.adrTostr(info.f_address) }}</span>
                            <div style="width:.22rem;margin-left:.1rem ">
                                <img src="../assets/imger/cc.png" alt="">
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="bindadr" @click="()=>{
                        if($store.state.walletType){
                            Toast('观察钱包不能交易');
                            return
                        }
                        adrShow=true;

                    } ">
                            <span>绑定上级</span>
                        </div>

                    </template>

                    
                  
                </div>


                <div class="btn d-flex align-center justify-between" style="padding:0 .6rem;margin-top:.6rem">

                    <van-button 
                        round
                        color="#006CFF"
                        style="width:2.7rem"

                        @click="()=>{
                            $router.push('./team')
                        }"
                    >我的团队</van-button>
                    <van-button 
                        round
                        color="#006CFF"
                        style="width:2.7rem"
                        @click="()=>{
                            $router.push('./inls')
                        }"
                    >入金订单</van-button>
                </div>               
            </div>
        </div>
        <div style="height:.6rem"></div>
    </BScroll>
    <!-- 注入USDT -->
    <div class="mask">
        <van-popup v-model:show="inShow"  round  :close-on-click-overlay="false" :before-close="fnclose"  :closeable="true">
        <div class="sell-mask">
              <div class="sell-title">
                  <span>注入USDT</span>
              </div> 
              <div class="inputBox">
                   <p>数量</p>
                  <div class="text">
                    <input type="text" v-model="amount" placeholder="输入数量">
                  </div>
                   <van-button class="sellbtn" color="#006CFF" :loading="inShowLoading"   round @click="ruUSDT" >注入</van-button>
              </div>
        </div>
      </van-popup>
    </div>
    <!-- 绑定上级 -->
    <div class="mask">
        <van-popup v-model:show="adrShow"  round  :close-on-click-overlay="false" :before-close="fnclose"  :closeable="true">
        <div class="sell-mask">
              <div class="sell-title">
                  <span>绑定上级</span>
              </div> 
              <div class="inputBox">
                   <p>邀请码</p>
                  <div class="text">
                    <input type="text" v-model="yyccode" placeholder="输入邀请码">
                  </div>
                   <van-button class="sellbtn" color="#006CFF" :loading="inShowLoading"   round @click="bindTeam" >绑定</van-button>
              </div>
        </div>
      </van-popup>
    </div>



  </div>
</template>

<script  setup> 
    import { reactive, toRefs,computed,watch, ref,onMounted } from 'vue'
    import { information,bind } from '@/common/api';
    import Header from '@/components/Header';
    import {useMainCoin,ruUSDTCoin} from "@/DApp/web3"
    import { useStore } from "vuex";
    import BScroll from '@/components/BScroll';
    import { Toast } from 'vant';
    import useClipboard from "vue-clipboard3";
    import file from "@/DApp/config/file";
    import UU from '@/utils/string'
    import {getBit} from '@/utils/string';
    const $store = useStore();
    const inShow = ref(false);
    const adrShow = ref(false);
    const inShowLoading = ref(false);
    const { toClipboard } = useClipboard();
    const LRcodeUrl =  `https://bscscan.com/address/${file.mainCoinAddress}#code`;
    let bannerls = ref([]);
    let info= ref("");
    let yyccode= ref("");
    let amount= ref("");

    let coinxx =ref({})
    const account = computed(()=>{
        return  $store.state.account;
    })

    const coininit = async ()=>{
        useMainCoin().then(res=>{
            coinxx.value = res;
        })
    }


    const fninit = ()=>{
        information({address:account.value}).then(res=>{
            if (res.code == 200) {
            info.value =res.data;
                bannerls.value = res.data.banner;
            }
        })

    }

    const bindTeam =()=>{
        if (!yyccode.value) {
            return
        }
        let params ={
            address:account.value,
            f_code:yyccode.value
        }
        inShowLoading.value = true;

        bind(params).then(res=>{
          
                  inShowLoading.value = false;
            if (res.code == 200) {
                amount.value ="";
                adrShow.value = false;
                Toast(res.msg);
                fninit();
            } else {
                Toast(res.msg);
            }
        })
    }

    const ruUSDT =()=>{

        const {balanceOf} = coinxx.value;

        if (!amount.value) {
            return
        }

        if (Number(balanceOf) < Number(amount.value)) {
           
            Toast("余额不足")
            return
        } 
        inShowLoading.value = true;

        ruUSDTCoin(amount.value).then(res=>{
            
            if (res) {
              amount.value ="";
              inShow.value = false;
              inShowLoading.value = false;
            } 
        })

    }


    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast.success('复制成功');
      } catch (e) {
        Toast.fail(e);
      }
    };



    watch(account,(newVal) => {
        newVal && fninit();

       
    }, {immediate:true,deep:true})

    onMounted(() => {
        coininit();

    })

</script>
<style scoped lang='scss'>


.mask{
   --van-popup-background-color:#0D1627;
 }

 .sell-mask{
      width: 5.7rem;
      height: 5rem;
      box-sizing: border-box;
      padding: .2rem .4rem;
     .sell-title{
         line-height: 1rem;
         text-align: center;
         font-size: .32rem;
        //  font-weight: bold;
         border-bottom: 1px solid #232A37 ;
     }
     .inputBox{
         padding: .2rem 0;

         p{
             line-height: .68rem;
             font-size: .24rem;
         }
        .text{
            background-color: #182743;
            display: flex;
            padding: 0 .3rem;
            align-items: center;
            justify-content: space-between;
            height: .68rem;
            border-radius: .03rem;
            input{
                font-size: .26rem;
               
                outline: none;border: 0;
                width: 100%
            }
        }
     }
     .sellbtn{
         width: 100%;
         height: .7rem;
         margin-top: .58rem;

     }
     .zhishi{
         text-align: center;
         color: #3B4559;
         font-size: .2rem;
     }
 
  }


.banner{
    height: 3rem;
}

.hdong{
    height: 2rem;
    padding: 0 .3rem;
    .lr{
        height: 1.1rem;
        width: 1.13rem;
        background-color: #182743;
        position: relative;
        .lrlogo{
            position: absolute;
            width: 1.13rem;
            height: 1.3rem;
            left: 0;
            bottom: -.1rem;
        }
    }
    .mmove{
        height: 1.1rem;
        width: .8rem;
    }
    .a0x00{
        flex: 1;
        height: 1.1rem;
        background-color: #182743;
        padding-left: .24rem;
        line-height: 1.1rem;
        font-size: .2rem;
 
        text-align: center;

    }

}

.accets{
    padding: .1rem .3rem;
    .inout{
        height: 3.3rem;
        .initem{
            width: 3.3rem;
            height: 3.3rem;
            background-image: url('../assets/imger/in.png');
            background-size: cover;
            padding: .2rem .3rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .title{
                color: #A1AEC5;

                .coin{
                    font-size: .22rem;
                    font-weight: normal;
                }
            }
            .balance{
                font-size: .42rem;
                font-weight: bold;
                line-height: 1rem;
                
            }
        }
        .out{
            background-image: url('../assets/imger/out.png');
            background-size: cover;
        }

        
    }

    .cucc{
        width: 100%;
        height: 1.9rem;   
        margin-top: .3rem;      
        background-image: url('../assets/imger/bee.png');
        background-size: cover;
        padding: 0 .3rem;
        padding-bottom: .2rem;
        .ls{
            color: #A1AEC5;
            line-height: .6rem;
            font-size: .24rem;
            text-align: right;
        }
        .cuccInfo{
            height: 1rem;
            padding-left: 1.2rem;
            .title{
                color: #A1AEC5;
            }
            .balance{
                text-align: right;
                font-size: .4rem;
                font-weight: bold;
                .usdtre{
                    font-size: .32rem;
                    line-height: .48rem;
                }
                .coin{
                    font-size: .22rem;
                    font-weight: normal;
                }
            }
        }
     }

}

.inusdt{
    height: 10rem;
    width: 100%;
    background-image: url('../assets/imger/bg.png');
    background-position: bottom  left;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: .48rem;
    .down{
        width: .7rem;height: .7rem;
        margin: auto;
    }
    .title{
        font-size: .36rem;font-weight: bold;
        line-height: .68rem;margin-top: .3rem;
        text-align: center;
    }
    .tts{
        color: #A1AEC5;font-size: .24rem;
        text-align: center;
    }
    .tt{
        width: 4.24rem;height: 3.64rem;
        margin: 1rem auto;
    }

    .coinInfo{
        padding: 0 .3rem;
        margin-top: .48rem;
        .coinItem{
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: .6rem;
            font-size: .26rem;
            .color{
                color: #A1AEC5;
            }
            .bindadr{
                color:#006CFF ;
            }
        }
    }
}

.yycode{
    height: 4.42rem;
    width: 100%;
    background-image: url('../assets/imger/bg2.png');
    background-size: 100% 100%;
    padding: .3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .down{
        width: .7rem;height: .7rem;
    }

    .title{
        font-size: .36rem;
        font-weight: bold;
        line-height: .8rem;
    }
    .codenum{
        height: 1.5rem;
        width: 100%;
        background: linear-gradient(90deg, #131F37, #182743);
        border-radius: .28rem;
        .copy{
            width: 1rem;
        }
        .code{
            text-align: center;
            font-family: DIN;
            font-weight: bold;
            font-size: .7rem;
            flex: 1;

        }
    }



}
</style>