import Web3 from 'web3';
import file from './config/file.js';
import { Notify } from 'vant'

let account = "";
let web3 ="";
if (window.ethereum) {
	window.ethereum.enable().then((res) => {
		account = res[0];
	})

   web3 = new Web3(Web3.givenProvider || 'https://bsc-dataseed.binance.org');

   web3.eth.getAccounts((error, result) => {
    if (error) {
      console.log(error);
    }

    web3.eth.defaultAccount = result[0];
   
  })
}
const contract = new web3.eth.Contract(file.mainCoin, file.mainCoinAddress);

const Ucontract = new web3.eth.Contract(file.usdtCoin, file.usdtCoinAddress);

const Rcontract = new web3.eth.Contract(file.runCoin, file.runCoinAddress);



const fromWei =(num)=>{

  return  web3.utils.fromWei(String(num),'ether')
}

export async function useMainCoin() {
    try {
      const name = await contract.methods.name().call();
      const totalNum = await contract.methods.totalNum().call();
      const minedNum = await contract.methods.minedNum().call();
      const burnMount = await contract.methods.burnMount().call();
      const balanceOf = await Ucontract.methods.balanceOf(account).call();
      const fee = await contract.methods.fee().call();
      return  {
        name,
        totalNum:fromWei(totalNum),
        minedNum:fromWei(minedNum),
        burnMount:fromWei(burnMount),
        fee,
        balanceOf:fromWei(balanceOf)
      };
    } catch (error) {
      console.error(error);
    }
}


export async function ruUSDTCoin(amounts){
  try {
    const amount = web3.utils.toWei(String(amounts),'ether');

    return new Promise((resolve, reject) => {
      Ucontract.methods.approve(file.runCoinAddress,amount).send({from:account})
      .on('transactionHash', ()=>{
        Notify({
          message: "授权中...",
          type: 'warning',
          duration: 0,
          });
      })
      .on("receipt",function(receipt){

        Notify({
          message: "请确认交易",
          type: 'warning',
          duration: 0,
          });


        Rcontract.methods.desposite(amount).send({from:account})
        .on('transactionHash', function (transactionHash) {
          Notify({
            message: "正在发起交易...",
            type: 'warning',
            duration: 0,
            });
        }).on('receipt', function (receipt) {
            Notify({ type: 'success', message: "交易成功..."});
            resolve({ receipt: receipt })
        })
        .on('error', function (error) {
          
            Notify({ type: 'primary', message:"交易失败"});
            reject({ error: error })
        })
      })

    })

    

    
  } catch (error) {
    console.log("error========>" + error)
  }


}

  
